import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {
    window.Form = new App({

        noResults: "\u0050\u0061\u0073\u0020\u0064\u0065\u0020\u0063\u006f\u0072\u0072\u00e9\u0073\u0070\u006f\u006e\u0064\u0061\u006e\u0063\u0065",
        messages: {
            required: '\u0052\u0065\u006d\u0070\u006c\u0069\u0072\u0020\u006c\u0065\u0020\u0063\u0068\u0061\u006d\u0070',
            phone: {
                incorrect: '\u004e\u0075\u006d\u00e9\u0072\u006f\u0020\u0064\u0065\u0020\u0074\u00e9\u006c\u00e9\u0070\u0068\u006f\u006e\u0065'
            },
            email: {
                incorrect: '\u0046\u006f\u0072\u006d\u0061\u0074\u0020\u0064\u0065\u0020\u006c\u0027\u0061\u0064\u0072\u0065\u0073\u0073\u0065\u0020\u0065\u0073\u0074\u0020\u0069\u006e\u0063\u006f\u0072\u0072\u0065\u0063\u0074\u0065',
                symbolNotFound: '\u004c\u0027\u0061\u0064\u0072\u0065\u0073\u0073\u0065\u0020\u0064\u006f\u0069\u0074\u0020\u0063\u006f\u006e\u0074\u0065\u006e\u0069\u0072\u0020\u006c\u0065\u0020\u0073\u0079\u006d\u0062\u006f\u006c\u0020\u201c\u0040\u201c\u002e\u0020\u0049\u006c\u0020\u006e\u0027\u0079\u0020\u0061\u0020\u0070\u0061\u0073\u0020\u0064\u0065\u0020\u0073\u0079\u006d\u0062\u006f\u006c\u0020\u201c\u0040\u201c\u0064\u0061\u006e\u0073\u0020\u006c\u0027\u0061\u0064\u0072\u0065\u0073\u0073\u0065',
                addressIsNotFull: '\u0045\u006e\u0074\u0072\u0065\u007a\u0020\u006c\u0061\u0020\u0070\u0061\u0072\u0074\u0069\u0065\u0020\u0064\u0065\u0020\u006c\u0027\u0061\u0064\u0072\u0065\u0073\u0073\u0065\u0020\u0061\u0070\u0072\u00e8\u0073\u0020\u006c\u0065\u0020\u0073\u0079\u006d\u0062\u006f\u006c\u0065\u0020\u201c\u0040\u201c\u002e\u002e\u0020\u004c\u0027\u0061\u0064\u0072\u0065\u0073\u0073\u0065\u0020\u006e\u0027\u0065\u0073\u0074\u0020\u0070\u0061\u0073\u0020\u0063\u006f\u006d\u0070\u006c\u00e8\u0074\u0065'
            },
            password: {
                minLength: '\u004e\u006f\u006d\u0062\u0072\u0065\u0020\u006d\u0069\u006e\u0069\u006d\u0061\u006c\u0020\u0064\u0065\u0073\u0020\u0073\u0079\u006d\u0062\u006f\u006c\u0073\u003a\u0020\u0036\u002e\u0020\u006d\u0061\u0069\u006e\u0074\u0065\u006e\u0061\u006e\u0074\u003a %current_length%'
            },
            repeatPassword: {
                discrepancy: '\u004c\u0065\u0073\u0020\u006d\u006f\u0074\u0073\u0020\u0064\u0065\u0020\u0070\u0061\u0073\u0073\u0065\u0073\u0020\u006e\u0065\u0020\u0073\u0065\u0020\u0063\u006f\u0072\u0072\u0065\u0073\u0070\u006f\u006e\u0064\u0065\u006e\u0074\u0020\u0070\u0061\u0073'
            },
            ofertaAgreement: {
                notChecked: '\u0043\u006f\u006e\u0066\u0069\u0072\u006d\u0065\u007a\u0020\u0076\u006f\u0074\u0072\u0065\u0020\u0061\u0063\u0063\u006f\u0072\u0064\u0020\u0061\u0076\u0065\u0063\u0020\u006c\u0065\u0073\u0020\u0072\u00e8\u0067\u006c\u0065\u0073'
            },
        },

        options: {
            regType: "phone",
            bonusType: "casino",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});